import './App.scss';
import 'bulma/css/bulma.css'
import { Route, Switch, useHistory, useRouteMatch,useLocation } from 'react-router-dom'
import Header from './commonComponents/Header'
import Home from "./AppComponents/Home"
import Games from "./AppComponents/Games"
import Game from "./AppComponents/Game"
import NotFound from "./AppComponents/NotFound"
import { useEffect, useState } from 'react';
import SideMenu from './AppComponents/SideMenu';
import ReactGA from 'react-ga';
import { useLanguage } from './Context/LangContext';
import { reactLocalStorage } from 'reactjs-localstorage';
import config from "./config"
import Category from './commonComponents/Category';
import Profile from "./AppComponents/Profile";
import Unsubscribe from './AppComponents/Unsubscribe';
import Language from './commonComponents/Language';
import queryString from "query-string";
function App() {
      const {language,changeLanguage}=useLanguage();
      const [sideMenu, setSideMenu] = useState(false)
      const [showSideMenu, setshowSideMenu] = useState(true)
      const [allGames, setAllGames] = useState([])
      const [loading , setLoading] = useState(true)
      const [banners,setBanners]=useState([])
      const [games,setGames]=useState([])
      const match1 = useRouteMatch('/game/:id')
      const [appConfigData, setappConfigData] = useState({})
      console.log(">>>route match:::", match1)
      const history = useHistory();
      const {search}=useLocation();
      const {lang} = queryString.parse(search);
      useEffect(() => {
            if(lang)
                  {
                      reactLocalStorage.set('lang',lang)
                      changeLanguage(lang)
                  }
            getAppConfigData()
      }, []);

      useEffect(() => {
            console.log("Analytics initialize")
            const ga_tag = config.get("ga_tag");
            const ga_debug = config.get("ga_debug");
            console.log("GOOGLE ANALYTICS DEBUG :: ", ga_tag, " :: ", ga_debug);
            ReactGA.initialize(ga_tag, {
              debug: ga_debug,
              titleCase: false,
            });
            // to report page view
            history.listen((location) => {
              ReactGA.set({ page: location.pathname });
              ReactGA.pageview(location.pathname);
            });
          }, []);

      const getAppConfigData = () => {
            setLoading(true)
            const url = `${config.get('base')}${config.get('appConfig')}`;
            const headers={
                  ...config.get('headers'),
                  Language:lang || language
            }
            fetch(url, {
                  method: 'POST',
                  body: JSON.stringify({ "mcc": "652", "mnc": "04" ,"uid" : reactLocalStorage.get("uid") ? reactLocalStorage.get("uid") : ""}),
                  headers:headers
            })
                  .then(res => {
                        if (!res.ok) { throw res }
                        return res.json()
                  })
                  .then((result) => {
                        console.log('result message appConfig ::', result)
                        const appConfigObj = {
                              menu: result.menu,
                              menu_color: result.menu_color,
                              home_logo: result.home_logo,
                              menu_logo: result.menu_logo,
                              view_all:result.view_all,
                              bg_color:result.bkg_color,
                              play_text:result.play_btn_txt,
                              flow:result.subRedirectFlow,
                              view_btn_txt:result.view_btn_txt,
                              sub_url:result.sub_url,
                              unsub_mode:result.unsubMode,
                              sidemenu:result.sidemenu,
                              unsub_msg_txt:result.unsubData,
                              portrait_txt:result.portrait_txt,
                              rotate_txt:result.rotate_txt,
                              landscape_txt:result.landscape_txt,
                              status_txt:result.status_txt,
                              similar_cat_txt:result.similar_cat_txt
                        }
                        setappConfigData(appConfigObj)
                        setLoading(false)
                        setGames(result.games_list)
                        setBanners(result.banner)
                  }, 
                        (error) => {
                              console.log('error:::', error)
                        })
      }



      return (
            <section className={`hero is-fullheight bg`} style={{padding:"0px"}}>
                  {!(match1)  &&  <Header loading={loading} menuBar = {appConfigData.menu} 
                  sub_url={appConfigData.sub_url}
                  sidemenu={appConfigData?.sidemenu}
                  menuLogo={appConfigData.menu_logo} homeLogo={appConfigData.home_logo} handleClick={() => setSideMenu(!sideMenu)} showSideMenu={showSideMenu} 
                   setshowSideMenu={()=>setshowSideMenu(!showSideMenu)}  />}
                  <SideMenu menuColor= {appConfigData.menu_color} menuLogo={appConfigData.menu_logo} sideMenu={sideMenu} handleClose={() => setSideMenu(!sideMenu)} />
                  <Switch>
                        <Route path="/home">
                        <Home games={games}  banners={banners} bgColor={appConfigData.bg_color} view_all={appConfigData.view_all}
                          setLoading={setLoading}   
                         flow={appConfigData.flow}
                         loading={loading}
                         view_btn_txt={appConfigData.view_btn_txt}/>
                        </Route>
                        <Route exact path="/">
                        <Home games={games} setLoading={setLoading} loading={loading} banners={banners} bgColor={appConfigData.bg_color} view_all={appConfigData.view_all} flow={appConfigData.flow}                      
                         view_btn_txt={appConfigData.view_btn_txt}/>
                        </Route>
                        
                        <Route path="/games">
                              <Games allGames = {allGames}/>
                        </Route>
                        <Route path="/game/:title/:id">
                              <Game view_all={appConfigData.view_btn_txt}
                              portrait_txt={appConfigData.portrait_txt}
                              rotate_txt={appConfigData.rotate_txt}
                              landscape_txt={appConfigData.landscape_txt}
                              similar_cat_txt={appConfigData.similar_cat_txt}
                                />
                        </Route>
                  
                        <Route path="/profile">
                            <Profile status_txt={appConfigData.status_txt}/>
                        </Route>
                      
                        <Route path="/category">
                             <Category play={appConfigData.play_text} 
                              />
                        </Route>
                        <Route path="/language">
                             <Language langObj={appConfigData?.sidemenu?.language}
                              />
                        </Route>
                        <Route path="/unsub">
                            <Unsubscribe unsub_mode={appConfigData?.unsub_mode}
                                unsubButtonText={appConfigData?.sidemenu?.unsub}
                                unsubText={appConfigData?.unsub_msg_txt}
                                status_txt={appConfigData?.status_txt}
                                />
                        </Route>
                        <Route path="*">
                              <NotFound />
                        </Route>
                  </Switch>
            </section>
      );
}
export default App
