import React, { useEffect } from "react"
import { useHistory } from "react-router-dom";
 import btnLink from "./../assets/icons/play_button.png"
import RatingOrange from "./../assets/icons/complete.png"
import RatingGray from "./../assets/icons/star_gray.png"
import back from "./../assets/icons/back.png"
import "./BannerGame.scss"
import { Link } from "react-router-dom"
import defaultImage from "../assets/images/default-image.png"
import { reactLocalStorage } from 'reactjs-localstorage';
import config from "../config";
import { logEvent } from "../Analytics/AnalyticsEvent";
import { events, screen } from "../Analytics/EventName";
import RatingYellow from "./../assets/icons/complete.png"
import light from "./../assets/icons/light.png"
import RatingQuatar from "./../assets/icons/quatar.png"
import RatingHalf from "./../assets/icons/half.png"
import RatingThirdFourth from "./../assets/icons/thirdFourth.png"
function BannerGame(props) {
    console.log(">>>props::", props)
    const { game ,setIsLoading} = props
    let history = useHistory();
    // console.log("The rating of the current game is::",typeof(parseFloat(game.ratings))) 
    // console.log("The rating of the current game is::",Math.round(parseFloat(game.ratings)))   
    // const rating = Array(5)
    //     .fill(0)
    //     .map((item, i) => (i <= 4
    //         ? <img key={i} src={RatingYellow} alt="stars" className="mr-1" />
    //         : <img key={i} src={RatingGray} alt="stars" className="mr-1" />))
    // const rating=Array(5).fill(0).map((item,i)=>(i<Math.round(parseFloat(game.ratings))
    //          ? <img key={i} src={RatingYellow} alt="stars" className="mr-1" />
    //         : <img key={i} src={RatingGray} alt="stars" className="mr-1" />
    //         ))
    function rating(number){
        const num=parseFloat(number)
        let newArray=Array(5).fill().map((_,i)=>
        {
            return <img key={i} src={RatingYellow} alt="stars" className="mr-1 rating-icon" />
        })
        newArray[4]=num==4?(<img  src={RatingGray} alt="stars" className="mr-1 rating-icon" />):
        num>=4.0 && num<=4.2?<img src={RatingQuatar} alt="stars" className="mr-1 rating-icon" />:
        num>=4.3 && num<=4.5?<img src={RatingHalf} alt="stars" className="mr-1 rating-icon" />:
        num>=4.5 && num<=4.7?<img  src={RatingHalf} alt="stars" className="mr-1 rating-icon" />:
        <img src={RatingYellow} alt="stars" className="mr-1 rating-icon" />
        return newArray;
     
      }
    function addDefaultSrc(e) {
        e.target.src = defaultImage
    }

    function handleClick(gameUrl, game) {
        setIsLoading(true);
        console.log("game.gameName",game.gameName)
        logEvent(
            {
                screen: screen.gamePage,
                // event: events.gamePlayClick
                event: game.gameName
            },
            {
                gameName: game.gameName,
                mobile: reactLocalStorage.get('msisdn'),
                date: new Date(),
            });
        const subStatus = reactLocalStorage.get("subStatus");
        sessionStorage.setItem("reloadOnBack", "true");
        if (subStatus === 'active') {
            window.open(gameUrl, "_self")
        } else {
            if (config.get('domain') === 'btc') {
                const url = `${config.get('base')}${config.get('appConfig')}`
                fetch(url, {
                    method: 'POST',
                    body: JSON.stringify({ "mcc": "652", "mnc": "04" ,"uid" : reactLocalStorage.get("uid") ? reactLocalStorage.get("uid") : ""}),
                    headers: config.get('headers')
                })
                    .then(res => {
                        if (!res.ok) { throw res }
                        return res.json()
                    })
                    .then((result) => {
                        window.open(result?.sub_url, "_self")
                        // history.push("/subs")
                        console.log('result message appConfig ::', result)
                    },
                        (error) => {
                            console.log('error:::', error)
                        })
            } else {
                reactLocalStorage.set("gameUrl", gameUrl)
                history.push("/subscribe");
            }
        }
    }
    return (
        <div className="is-gapless pagebanner" style={{width:"100%",whiteSpace:"break-spaces",bottom:"-2rem"}} >
            <div className="column p-r p-0" >
                <Link to="/home" className="navitem" >
                   
                     <img src={back} alt="back to home"/>
                </Link>
                {/* <figure className="image " > */}
                    <img src={game.bannerImage} onError={addDefaultSrc} alt="demo" height="300px"  className="lazy-img" width="100%" style={{minHeight:"30vh",maxHeight:"40vh"}}/>
                {/* </figure> */}
                <div className="fixb">
                    <div className="media desc" >
                        <div className="media-left align-left" >
                            <h5 className="title is-capitalized is-5 pb-2 has-text-white mb-4 bold">{game.gameName}</h5>
                            <h6 className="subtitle is-7 has-text-white mb-0">{game.gamePlayed}</h6>
                            <div className="rating has-text-white">
                                {rating(game.ratings)}
                                <small className="has-text-weight-dark">
                                    {game.ratings}
                                    </small>
                            </div>
                        </div>
                        <div className="media-right play " >
                            <div onClick={() => handleClick(game.url, game)}>           
                                <img src={btnLink} alt="game page link m-auto" className="play-btnn" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="column mt-8">
                <div className="p-3">
                    <p className="subtitle is-6 has-text-white game-desc" >{game.gameDescription}</p>
                </div>
            </div>
        </div>
    )
}

export default BannerGame