import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import CommonSlider from "../commonComponents/CommonSlider";
import BannerGame from "../commonComponents/BannerGame";
import config from "../config";
import _ from "lodash";
import { reactLocalStorage } from "reactjs-localstorage";
import rotate from "../assets/icons/rotate_device.png";
import "./LandscapePopUp.scss";
import { useLanguage } from "../Context/LangContext";
import close from "../assets/icons/popup_close_icon.png"
function Game({ view_all,portrait_txt,rotate_txt,landscape_txt,similar_cat_txt }) {
  const [allGames, setAllGames] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [games, setGames] = useState();
  const [landscapegames, setLandscapeGames] = useState();
  const [selectedGame, setSelectedGame] = useState();
  const [selectedLandscapeGame, setSelectedLandscapeGame] = useState();
  const [remGames, setRemGames] = useState();
  const [landscaperemGames, setLandscapeRemGames] = useState();
  const [showModal, setShowModal] = useState(true);
  const [showLandscapeModal, setShowLandscapeModal] = useState(true);
  const modeRef = useRef(null);
  const {language}=useLanguage();
  console.log("selectedGame:::",selectedGame)
  useEffect(() => {
    console.log("outside reloading");
    // window.onpageshow = function(event) {
    //   if (event.persisted) {
    //     console.log("reloading")
    //     window.location.reload();
    //   }
    // };
    // const handlePageShow = (event) => {
    //   if (event.persisted) {
    //     console.log("reloading");
    //     window.location.reload();
    //   }
    //   // Add the event listener when the component mounts
    //   window.addEventListener("pageshow", handlePageShow);

    //   // Remove the event listener when the component unmounts
    //   return () => {
    //     window.removeEventListener("pageshow", handlePageShow);
    //   };
    // };
    // window.scrollTo(0, 0);
    // return()=>setIsLoading(false)
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        if (sessionStorage.getItem("reloadOnBack")) {
          sessionStorage.removeItem("reloadOnBack");
          getAppConfigData();
        }
      }
    };
  
    document.addEventListener("visibilitychange", handleVisibilityChange);
  
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
  }
  }, []);

  useEffect(() => {
    console.log("game 1");
    getAppConfigData();
    window.scrollTo(0, 0);
    return()=>setIsLoading(false)
  }, []);

  // useEffect(() => {
  //   const handleOrientationChange = () => {
  //     const isPortrait = window.innerWidth < window.innerHeight;
  //     if (
  //       isPortrait &&
  //       selectedGame &&
  //       selectedGame.mode.toUpperCase() === "LANDSCAPE"
  //     ) {
  //       setShowModal(true);
  //     } else {
  //       setShowModal(false);
  //     }
  //   };

  //   window.addEventListener("orientationchange", handleOrientationChange);

  //   return () => {
  //     window.removeEventListener("orientationchange", handleOrientationChange);
  //   };
  // }, [selectedGame]);

  // useEffect(() => {
  //   const handleOrientationChange = () => {
  //     const isLandscape = window.innerHeight < window.innerWidth;
  //     if (
  //       isLandscape &&
  //       selectedGame &&
  //       selectedGame.mode.toUpperCase() === "PORTRAIT"
  //     ) {
  //       setShowLandscapeModal(true);
  //     } else {
  //       setShowLandscapeModal(false);
  //     }
  //   };

  //   window.addEventListener("orientationchange", handleOrientationChange);

  //   return () => {
  //     window.removeEventListener("orientationchange", handleOrientationChange);
  //   };
  // }, [selectedGame]);

  console.log("inside game container");
  const { title, id } = useParams();
  console.log(">>>params id:::", title);
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
  };

  function handleResize() {
    const isPortrait = window.matchMedia("(orientation: portrait)").matches;
    if (isPortrait && modeRef?.current?.toUpperCase() === "LANDSCAPE") {
      window.scrollTo(0, document.body.scrollHeight);
      setShowModal(true);
    } else {
      setShowModal(false);
    }
    window.scrollTo(0, 0);
    // window.addEventListener("resize", handleResize);
    return showModal;
  }

  function handleLandscapeResize() {
    const isLandscape = window.matchMedia("(orientation: landscape)").matches;
    if (isLandscape && modeRef?.current?.toUpperCase() === "PORTRAIT") {
      window.scrollTo(0, document.body.scrollHeight);
      setShowLandscapeModal(true);
    } else {
      setShowLandscapeModal(false);
    }
    window.scrollTo(0, 0);
    // window.addEventListener("resize", handleResize);
    return showLandscapeModal;
  }

  useEffect(() => {
    if (window.matchMedia("(orientation: portrait)").matches) {
      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleLandscapeResize);
    }

    if (window.matchMedia("(orientation: landscape)").matches) {
      window.addEventListener("resize", handleLandscapeResize);
      return () => window.removeEventListener("resize", handleResize);
    }
  }, [handleResize, handleLandscapeResize]);

  // useEffect(() => {
  //   // console.log("insdie ndgd");
  //   window.addEventListener("landscaperesize", handleLandscapeResize);
  //   return () =>
  //     window.removeEventListener("landscaperesize", handleLandscapeResize);
  // }, [selectedLandscapeGame]);

  const getAppConfigData = () => {
    const url = `${config.get("base")}${config.get("appConfig")}`;
    const headers={
      ...config.get("headers"),
      Language:language
    }
    fetch(url, {
      method: "POST",
      body: JSON.stringify({
        mcc: "652",
        mnc: "04",
        uid: reactLocalStorage.get("uid") ? reactLocalStorage.get("uid") : "",
      }),
      headers: headers ,
    })
      .then((res) => {
        if (!res.ok) {
          throw res;
        }
        return res.json();
      })
      .then(
        (result) => {
          console.log("result message appConfig ::", result);
          setAllGames(result.games_list);
          setIsLoading(false);
        },
        (error) => {
          console.log("error:::", error);
        }
      );
  };
  // console.log("allGames:::",allGames)
  const curGames = _.find(allGames, function (o) {
    return o?.contents?.[0]?.category == title;
  });


  useEffect(() => {
    setGames(curGames?.contents);
    if (games && curGames) {
      const game = games.find((item, i) => item.id == id);
      const remGames = games.filter((item, i) => item.id != id);
      setSelectedGame(game);
      setRemGames(remGames);
    }
    window.scrollTo(0, 0);
  }, [curGames, games, id]);

  useEffect(() => {
    setLandscapeGames(curGames?.contents);
    if (landscapegames && curGames) {
      const landscapegame = landscapegames.find((item, i) => item.id == id);
      const landscaperemGames = landscapegames.filter(
        (item, i) => item.id != id
      );
      setSelectedLandscapeGame(landscapegame);
      setLandscapeRemGames(landscaperemGames);
    }
  }, [curGames, landscapegames, id]);
  const isPortrait = window.matchMedia("(orientation: portrait)").matches;
  console.log("is portrait ------> ", isPortrait);
  useEffect(() => {
    if (selectedGame) {
      modeRef.current = selectedGame.mode;
      if (
        selectedGame.mode === "landscape" &&
        window.matchMedia("(orientation: portrait)").matches
      ) {
        setShowModal(true);
      } else {
        setShowModal(false);
      }
    }
  }, [selectedGame]);

  useEffect(() => {
    if (selectedLandscapeGame) {
      modeRef.current = selectedLandscapeGame.mode;
      if (
        selectedLandscapeGame.mode === "portrait" &&
        window.matchMedia("(orientation: landscape)").matches
      ) {
        setShowLandscapeModal(true);
      } else {
        setShowLandscapeModal(false);
      }
    }
  }, [selectedLandscapeGame]);
  return (
    // <>
    // {isLoading?<div className="lds" style={{marginTop:"70%"}}></div>:
    //   <React.Fragment>
    //       {selectedGame && <div>
    //       <BannerGame game={selectedGame}/>
    //       <div className="container m-w mt-6" >
    //          {games && remGames && <CommonSlider
    //               settings={{
    //               ...settings,
    //               dots: false,
    //               arrows: false,
    //               slidesToShow: 2,
    //               slidesToScroll: 1,
    //               centerMode: true,
    //               centerPadding: '10%',
    //               }}
    //               title="Similar Related Games"
    //               data={remGames}
    //               view_btn_txt={view_all}
    //             //   icon={.icon}
    //               category={selectedGame.category}
    //               />}</div>
    //       </div>}
    //       </React.Fragment>}
    //       </>
    <>
      {isLoading && <div className="loader-container">
      <div className="lds" ></div>
      </div>
      }
      {!isLoading && (
        <React.Fragment>
          {selectedGame && (
            <div>
              <BannerGame game={selectedGame} setIsLoading={setIsLoading} />
              <div className="container m-w mt-6">
                {games && remGames && (
                  <CommonSlider
                    settings={{
                      ...settings,
                      dots: false,
                      arrows: false,
                      slidesToShow: 2,
                      slidesToScroll: 1,
                      centerMode: true,
                      centerPadding: "10%",
                    }}
                    title={similar_cat_txt}
                    data={remGames}
                    view_btn_txt={view_all}
                    //   icon={.icon}
                    category={selectedGame.category}
                  />
                )}
              </div>
            </div>
          )}
          {/* {selectedLandscapeGame && (
            <div>
              <BannerGame game={selectedLandscapeGame} />
              <div className="container m-w mt-6">
                {landscapegames && landscaperemGames && (
                  <CommonSlider
                    settings={{
                      ...settings,
                      dots: false,
                      arrows: false,
                      slidesToShow: 2,
                      slidesToScroll: 1,
                      centerMode: true,
                      centerPadding: "10%",
                    }}
                    title="Similar Related Games"
                    data={landscaperemGames}
                    view_btn_txt={view_all}
                    //   icon={.icon}
                    category={selectedLandscapeGame.category}
                  />
                )}
              </div>
            </div>
          )} */}
          {console.log(showModal,window.innerWidth < window.innerHeight,"modal")}
          {showModal && (
            <div className="pop-bg container is-gapless pagebanner column p-r p-0">
              <div className="popup-modal">
              <img src={close} alt="close" className='model-close' onClick={()=>
              {
                setShowModal(false)
              }}/>
                <img
                  src={rotate}
                  alt="rotate"
                  className="rotate-img"
                  width="90"
                />
                <div className="modal-content">
                <h1 className="column p-r p-0  text-lg bold">             
                    {rotate_txt}
                  </h1>
                  <h1 className="column p-r p-0 ">
                    {/* To begin the game,please rotate your device and switch to
                    landscape mode. */}
                    {landscape_txt}
                  </h1>
                </div>
              </div>
            </div>
          )}
          {showLandscapeModal && (
            <div className="pop-bg container is-gapless pagebanner column p-r p-0">
              <div className="popup-modal2">
              <img src={close} alt="close" className='model-close' onClick={()=>
              {
                setShowLandscapeModal(false)
              }}/>
                <img
                  src={rotate}
                  alt="rotate"
                  className="rotate-img"
                  width="90"
                />
                <div className="modal-content">
                  <h1 className="column p-r p-0  text-lg bold">             
                    {rotate_txt}
                  </h1>
                  <h1 className="column p-r p-0 ">
                    {/* To begin the game,please rotate your device and switch to
                    portrait mode. */}
                    {portrait_txt}
                  </h1>
                </div>
              </div>
            </div>
          )}
        </React.Fragment>
      )}
    </>
  );
}

export default Game;
