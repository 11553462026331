import React, { useEffect, useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../AppComponents/Games.scss";
import _ from "lodash";
import { useHistory } from "react-router-dom";
import { reactLocalStorage } from "reactjs-localstorage";
import ConfirmPopup from "./ConfirmPop";
import config from "../config";
import tick from "../assets/icons/green_tick.png"
import "./Profile.scss";
import { useLanguage } from "../Context/LangContext";

function Unsubscribe({ unsub_mode,unsubButtonText,unsubText,status_txt }) {
  const {language}=useLanguage();
  const history = useHistory();
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showUnsubBtn, setShowUnsubBtn] = useState(false);
  const [loading, setLoading] = useState(false);
  const [resultModal, setResultModal] = useState(false);
  const [message, setMessage] = useState("");
  useEffect(() => {
    setLoading(false);
    const showUnSub = reactLocalStorage.get("showUnsub");
    setShowUnsubBtn(showUnSub);
  }, []);
  function handleModal() {
    setShowConfirmModal(!showConfirmModal);
  }

  function handleBtnClick() {
    setLoading(true);
    const url = `${config.get("base")}${config.get("unsubscribe")}`;
    const headers={
      ...config.get("headers"),
      Language:language
    }
    fetch(url, {
      method: "POST",
      body: JSON.stringify({
        uid: reactLocalStorage.get("uid") ? reactLocalStorage.get("uid") : "",
      }),
      headers:headers,
    })
      .then((res) => {
        if (!res.ok) {
          throw res;
        }
        return res.json();
      })
      .then(
        (result) => {
          console.log("result message::", result);
          setMessage(result?.message);
          setResultModal(true);
          setLoading(false);
          reactLocalStorage.set(
            "show_un_sub",
            JSON.stringify(result?.showUnsub)
          );
        },
        (error) => {
          console.log("error:::", error);
          setLoading(false);
        }
      );
  }

  return (
    <>
      {loading ? (
        <div className="ld-bg">
          <div className="lds"></div>
        </div>
      ) : (
        <>
          <div>
            <div className="container m-w">
              <div className="columns is-mobile">
                <div className="column is-full">
                  <div className="profile-card">
                    <div className="card-content ">
                      <div className="display">
                        <div className="text-start">
                          <h3 className="">{status_txt}</h3>
                          <div className="user-status">
                      <h2 className="mt-4 semibold">{reactLocalStorage.get("status_display")} </h2>
                     <img src={tick} alt="tick" className="image" style={{marginTop:"1rem",marginLeft:".25rem"}} />
                     </div>
                        </div>
                        <div className="text-start">
                          <h3 className="">MSISDN</h3>
                          <h2 className="mt-4">
                            {reactLocalStorage.get("msisdn")}
                          </h2>
                        </div>
                      </div>
                    </div>
                    <button
                      className="btn text-white bold"
                      onClick={() => setShowConfirmModal(true)}
                    >
                      {unsubButtonText}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {showConfirmModal && (
        <ConfirmPopup
          cancelText={unsubText?.DOI?.cancelBtnTxt}
          okText={unsubText?.DOI?.okayBtnTxt}
          message={unsubText?.DOI?.message}
          title={unsubButtonText}
          showConfirmModal={showConfirmModal}
          setShowConfirmModal={handleModal}
          loading={loading}
          setLoading={setLoading}
          handleUnsub={handleBtnClick}
          mode={unsub_mode}
        />
      )}
      {resultModal && (
        <div className="modal-bg">
          <div className="model-content">       
              <p className="text-center bold w-full max-w-full break-words text-white text-lg text-center">{message}</p>       
              <button
                className="modalbtn margin text-white button-bg"
                onClick={() => history.push("/")}
              >
               {unsubText?.DOI?.okayBtnTxt}
              </button>
        
          </div>
        </div>
      )}
    </>
  );
}
export default Unsubscribe;
