import React from "react"
import BannerSlider from "../commonComponents/BannerSlider"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import "./Games.scss"
import homeGames from "../assets/mock/db/banners/home.json"
// import allGames from "../assets/mock/db/games/all.json"
import GameCard from "./../commonComponents/GameCard"

function Games({allGames}) {
    console.log('inside games container', allGames)
    const settings = {
        dots: true,
        infinite: true,
        speed: 500
    };
    return (
        <div className="container m-w">
            <BannerSlider
                settings={{
                ...settings,
                arrows: false,
                slidesToShow: 1,
                slidesToScroll: 1,
                swipeToSlide: true
            }}
                data={homeGames}/>

            <div className="columns is-multiline is-mobile common is-gapless">
                <div className="column is-full v-center">
                    <h6 className="title is-6 has-text-white mb-0 line">All Games</h6>
                </div>
                <div className="column mt-3 is-full">
                    {
                       allGames && allGames.map((item, i) => (
                            <GameCard key={item.id} data={item} />
                        ))
                    }
                </div>
            </div>
        </div>
    )
}

export default Games