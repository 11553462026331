import React, { useEffect, useState } from "react"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import "./Home.scss"
import BannerSlider from "../commonComponents/BannerSlider"
import CommonSlider from "../commonComponents/CommonSlider"
import config from "../config"
import { reactLocalStorage } from 'reactjs-localstorage'
import Modal from 'react-modal';
import { useHistory, useLocation } from "react-router-dom";
import queryString from "query-string";
import _ from "lodash";
import { logEventHome } from "../Analytics/AnalyticsEvent"
import { screen } from "../Analytics/EventName"
import { useLanguage } from "../Context/LangContext"

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '80%'
    },
};

Modal.setAppElement('#root');

function Home({ games,banners,view_btn_txt,loading,setLoading ,flow}) {
    const {language,changeLanguage}=useLanguage();
    const [modalIsOpen, setIsOpen] = useState(false);
    const [heMsg, setHeMsg] = useState('')
    let history = useHistory();
    let location = useLocation();
    let params = queryString.parse(location.search);
    console.log("params >>>>>>>>>", params)
    let { search } = useLocation();
    const { uid,lang} = queryString.parse(search);
    const settings = {
        dots: true,
        infinite:true,
        speed: 500
    };
    
    useEffect(() => {
        if(!uid){
            reactLocalStorage.set("uid","qu2demo")
            reactLocalStorage.set("msisdn","701701701")       
        }
            console.log("uid", uid)
            if (uid !== '' && uid !== undefined) {
                reactLocalStorage.set("uid", uid);
                checksub(uid)              
            }
            else {
                if (reactLocalStorage.get("uid") !== '' && reactLocalStorage.get("uid") !== undefined) {
                    checksub(reactLocalStorage.get("uid"))
                }else{
                    if(!flow || flow==="home")
                    {
                    const url = `${config.get('base')}${config.get('appConfig')}`
                    fetch(url, {
                        method: 'POST',
                        body: JSON.stringify({ "mcc": "652", "mnc": "04" ,"uid" : reactLocalStorage.get("uid") ? reactLocalStorage.get("uid") :""}),
                        headers: config.get('headers')
                    })
                        .then(res => {
                            if (!res.ok) { throw res }
                            return res.json()
                        })
                        .then((result) => {
                            logEventHome(
                                {
                                    screen: screen.homePage,
                                    event: "Redirected to" + result.sub_url
                                },
                                {
                                   
                                });
                          
                            window.open(result?.sub_url, "_self")
                            console.log('result message appConfig ::', result)
                        },
                            (error) => {
                                console.log('error:::', error)
                            })
                }
                else{
                    setLoading(false)
                }
            }          
        }
    }, []);
   
    function capatalize(title) {
        return title?.charAt(0)?.toUpperCase() + title?.slice(1);
    }

    const checksub = (uid) => {
        const url = `${config.get('base')}${config.get('checkSub')}`
        const headers={
            ...config.get('headers'),
            Language:lang || language
        }
        fetch(url, {
            method: 'POST',
            body: JSON.stringify({ 'uid': uid }),
            headers: headers
        })
            .then(res => {
                if (!res.ok) { throw res }
                return res.json()
            })
            .then((result) => {
                console.log('result message::', result)
                reactLocalStorage.set("subStatus", result.subStatus)
                reactLocalStorage.set("status_display", result.status_display)
                reactLocalStorage.set("showUnsub",result.showUnsub)
                reactLocalStorage.set("msisdn", result.msisdn);
                setLoading(false)
                logEventHome(
                    {
                        screen: screen.homePage,
                        event: "checksub_api_success"
                    },
                    {
                        others : {
                            status : result.subStatus  ? result.subStatus  : "",
                            uid : uid  ? uid  : "NA"
                        }
                    });
            },
                (error) => {
                    console.log('error:::', error)
                })
    }
    

    function openModal() {
        setIsOpen(true);
    }

    function closeModal() {
        setIsOpen(false);
    }

    function handleClick() {
        if (config.get('domain') === 'btc') {
            window.location.reload();
        } else {
            history.push('/login')
        }
    }

    return (
        <>
        {
            loading?<div className="loader-container">
            <div className="lds" >
            </div>
            </div>:
        
        <div className="container m-w">
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal"
            >
                <div>{heMsg}</div>
                <button
                    onClick={handleClick}
                    style={{
                        width: '23%',
                        backgroundColor: 'rgb(23 54 206)',
                        color: 'white',
                        padding: '4% 1%',
                        margin: '8px 0',
                        border: 'none',
                        borderRadius: '4px',
                        cursor: 'pointer',
                        fontWeight: 'bold'
                    }}
                >Okay</button>
            </Modal>
            {banners && banners.length?(
            <BannerSlider
                settings={{
                    ...settings,
                    arrows: false,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    swipeToSlide: true
                }}
                data={banners}/>):""} 
               
            {games &&
            _.map(games).map((item, index) => (
              <CommonSlider
                settings={{
                  ...settings,
                  dots: false,
                  arrows: false,
                  slidesToShow: 3,
                  slidesToScroll: 1,
                  centerPadding: "10%",
                  swipeToSlide: true,
                }}
                title={capatalize(item.title)}
                data={item.contents}
                key={index}
                category={item.title}
                view_btn_txt={view_btn_txt}
              />
            ))}

        </div>}
        </>
    )
}

export default Home