import React from "react"
import "./GameCard.scss"
import RatingYellow from "./../assets/icons/complete.png"
import {Link} from "react-router-dom"
import { logEvent } from "../Analytics/AnalyticsEvent"
import { screen } from '../Analytics/EventName';
import { reactLocalStorage } from "reactjs-localstorage"
import btnLink from "./../assets/icons/games.png"

function GameCard(props) {
    const {data,play_text} = props
    
  return (
    <div className="card-bg mb-3 p-0" >
         <div className="card-content p-0">
                <div className="media">
                    <div className="media-left">
                        <figure className="image is-120x120">
                            <img src={data.gameImage} alt="Placeholder" className="img-radius lazy-img" />
                        </figure>
                    </div>
                    <div className="">
                    <p className="title is-capitalized is-6 text-white text-left pt-3" style={{whiteSpace:"break-spaces"}}>{data.gameName}</p>
                    <div className="gamecard-wrapper">
                        <div className="">
                        {/* <p className="title is-capitalized is-6 text-white">{data.gamePlayed}</p> */}
                        <p className="title is-capitalized is-6 text-white">{data?.gamePlayed}</p>
                        <div className="text-left mr">
                            <img src={RatingYellow} className="star-icon rating-icon" />
                            <small className="" style={{marginTop:0}}>{data?.ratings}</small>
                        </div>
                        </div> 
                        <div >
                        <Link to={`/game/${data?.category}/${data?.id}`}
                        onClick={() => {
                            console.log("data.gameName", data?.gameName + "_cardClick")
                            logEvent(
                                {
                                    screen: screen.homePage,
                                    event : data?.gameName + "_cardClick"
                                },
                                {
                                    gameName : data?.gameName,
                                    mobile : reactLocalStorage.get('msisdn'),
                                    date: new Date(),
                                });
                        }}>
                            <div className="playBtn">
                            <img src={btnLink} className="play-btn"/> 
                            <h1 className="text-white pl-1 mt-2 m-auto ">{play_text}</h1> 
                             </div>
                            </Link>
                        </div>
                    </div>
                    </div>
                </div>
         </div>
    </div>
  )
}

export default GameCard