import React, { useEffect, useState } from "react"
import { reactLocalStorage } from "reactjs-localstorage"
import './Profile.scss'
import tick from "../assets/icons/green_tick.png";

function Profile({ status_txt }) {
    const [displayStatus, setDisplayStatus] = useState('')
    const [showUnSub, setShowUnSub] = useState('')

    useEffect(() => {
        const displayStatus = reactLocalStorage.get("status_display")
        const show_unsub = reactLocalStorage.get("showUnsub")
        setDisplayStatus(displayStatus)
        setShowUnSub(show_unsub)
    }, [])

    function capitalizeFirstLetter(string) {
        if (string.toLowerCase() === 'failed') {
            string = "inactive"
        }
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    return (
        <>

            <div className="container m-w">
                <div className="columns is-mobile">
                    <div className="column is-full">
                        <div className="profile-card">
                            <div className="card-content ">
                                <div className="display">
                                    <div className="text-start">
                                        <h3 className="">{status_txt}
                                        </h3>
                                        {displayStatus ? <div className="user-status">
                                            <h2 className="mt-4 semibold">{capitalizeFirstLetter(displayStatus)} </h2>
                                            <img src={tick} alt="tick" className="image" style={{ marginTop: "1rem", marginLeft: ".25rem" }} />
                                        </div>
                                            : <h2 className="mt-4">--</h2>
                                        }
                                    </div>
                                    <div className="text-start">
                                        <h3 className="">MSISDN</h3>
                                        {reactLocalStorage.get("msisdn") ? <h2 className="mt-4 semibold">{reactLocalStorage.get("msisdn")}</h2> :
                                            <h2 className="mt-4">--</h2>}
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Profile
