import React, { useState } from 'react'
import './Modal.scss'
import close from "../assets/icons/popup_close_icon.png"
import modal from "../assets/icons/subscribe_unsubscribe_icon.png"
import { reactLocalStorage } from 'reactjs-localstorage'
const ConfirmPopup = (props) => {
    console.log("props:::",props)
    const [loading,setLoading]=useState("");
    
    return (
        // <div>
        //     <div className={props.show ? "backdrop" : ""}></div>
        //     <div className="Modal" style={{backgroundColor:props.menuColor}}>
        //         {props.children}
        //         <button className='modalbtn margin' onClick={props.closeModal}>Cancel</button>
        //         <a href="sms:9306;?&amp;body=OK" className='sms-btn msgbtn'>Okay</a>
        //         {/* <button className='modalbtn' onClick={props.handleSubmit}>Okay</button> */}
        //     </div>
        // </div>
        <div className="modal-bg">
        <div className="model-content text-white"> 
        <img src={close} alt="close" className='model-close' onClick={props?.setShowConfirmModal}/>
               <img src={modal} alt="modal" className='modal-img' />   
       
        <h1 className="text-center text-lg bold m-2">{props?.title}</h1>
        <h2 className="text-center  px-5">
         {props?.message}
         </h2>
        <button className='modalbtn margin text-white capitailize bg-black ' onClick={props.setShowConfirmModal} >{props?.cancelText}</button>
        {props.mode==="sms"?<a href="sms:9306;?&amp;body=OK" className='sms-btn msgbtn button-bg text-white modalbtn'>{props?.okText}</a>:<button className='modalbtn margin text-white button-bg' onClick={()=>{
            props.setShowConfirmModal();
            props.handleUnsub()}} >{props?.okText}</button>}
        </div>
       </div>
    )
}

export default ConfirmPopup;
