import React, { useEffect, useState } from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import { reactLocalStorage } from "reactjs-localstorage";
import back from "./../assets/icons/back.png";
import closeIcon from "../assets/icons/menu_close_icon.svg";
import "../AppComponents/SideMenu.scss";
import profile from "../assets/icons/profile_menu_icon.png";
import {BiLogOut} from "react-icons/bi";
import {HiOutlineMenu} from "react-icons/hi";
import language from "../assets/icons/language_menu_icon.png";
import unsub from "../assets/icons/subscribe_menu_icon.png";
import Language from "./Language";
function Header({ sub_url, setshowSideMenu,menu_color,menuLogo,view_btn_color,homeLogo,sidemenu }) {
  const [sideMenu, setSideMenu] = useState(false);
  let { pathname } = useLocation();
  const history = useHistory();
  const [loading,setLoading]=useState(false)
  const [showLangModal,setShowLanguageModal]=useState(false)
  function handleSubscribe()
  {
      setLoading(true)
      window.open(sub_url, "_self")
      setSideMenu(false)
  }
  return (
    <nav
      className="navbar"
      role="navigation"
      aria-label="main navigation"
      style={{ display: "flex", flexDirection: "row",position:'relative' }}
    >
      {(pathname == "/" || pathname == "/home") ? (
        <>
        {  <div
            className="p-4 fixed-width"
            onClick={() => {
              console.log("Sidemenu opened");
              setSideMenu(true);
            }}
          >
            <HiOutlineMenu
             color="white"
             size={40}
            />
          </div>
         }
          <div
            className="navbar-brand fixed-width"
            style={{ marginLeft: "2rem" }}
          >
           {homeLogo && <div className="px-2 pb-2">
              <img
                src={homeLogo}
                alt="logo"
                style={{ height: "80px" }}
                id="center-img"
              />
            </div>
          }
          </div>
        </>
      ) : (
        <>
          <div
            className="navitem fixed-width"
            style={{ padding: "10px" }}
            onClick={() => {
              history.goBack();
              setshowSideMenu(true);
            }}
          >
            <img src={back} alt="back to home" />
          </div>
          <div
            className="navbar-brand fixed-width"
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginLeft: "9rem",
            }}
          >
           {homeLogo && <div className="px-2 pb-2">
              <img
                src={homeLogo}
                alt="logo"
                style={{ height: "80px" }}
                id="right-img"
              />
            </div>
          }
          </div>
        </>
      )}

      {sideMenu && (
        <div className="ld-bg">
          <div
            className="sideMenu"
            style={{ background:menu_color }}
          >
            <div className="line-bottom" style={{background:view_btn_color}}>
            
              <div className="p-4 display menu-logo-img">
                <div className="" style={{ margin:'auto'}}>
                  <img src={menuLogo} alt="logo" height="70px" width="90px" />
                </div>
                <div
                style={{ float: "right" }}
                onClick={() => setSideMenu(false)}
              >
                <img src={closeIcon} alt="close" />
              </div>
              </div>
            </div>
            {sidemenu?.profile && <Link  to="/profile" className="links text-lg text-white p-4 line-bottom justifyContent" onClick={()=>setSideMenu(false)}>
            <img src={profile} alt="profile" className="menu-icon"/>
            <span className="link">
             {sidemenu?.profile}
            </span></Link>}
            {/* {(!reactLocalStorage.get("msisdn") || !reactLocalStorage.get("uid") || reactLocalStorage.get("subStatus")!=='active') &&
           <Link  to="#" className="links text-lg text-white p-4 line-bottom justifyContent" onClick={handleSubscribe}
            >
            <BiLogOut size={25}/>
           <span className="link">	تسجيل الدخول</span></Link>
            } */}
          {
           sidemenu?.unsub && reactLocalStorage.get("subStatus")=='active' && reactLocalStorage.get("msisdn")   &&
            <Link  to="/unsub" className="links text-lg text-white p-4 line-bottom justifyContent" onClick={()=>
                { 
                setSideMenu(false)}}>
                  <img src={unsub} alt="unsubscribe" className="menu-icon"/>
                  <span className="link">{sidemenu?.unsub}</span></Link>     
           }
          {sidemenu?.language && <Link  to="/language" className="links text-lg text-white p-4 line-bottom justifyContent" onClick={()=>
            {
              setShowLanguageModal(true)
              setSideMenu(false)
           }}>
            <img src={language} alt="language" className="menu-icon"/>
            <span className="link">
            {sidemenu?.language?.title}
            </span>
            </Link>
}
          </div>
        </div>
      )}
      {/* {showLangModal && <Language setShowLangModal={setShowLanguageModal} setshowSideMenu={setSideMenu} langObj={sidemenu?.language}/>} */}
    </nav>
  );
}

export default Header;
