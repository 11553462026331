import config from 'react-global-configuration'
config.set({
    ga_tag: "",
    ga_debug: false,
    base:"https://telecubesapis.bngrenew.com/apn",
    domain : "btc",
    he : "/he",
    subscribe: '/subscribe',
    requestOTP: "/requestOTP",
    activate: "/activate",
    checkSub: "/checkSub",
    unsubscribe : '/unsubscribe',
    userPacks : '/userPacks',
    appConfig : '/config' , 
    headers: {
        // "Buildtype":"demo",
        'Reqfrom': 'web',
        'Username': 'web',
        'Servicefor':'cg_demo'
    }
})


export default config