import React, { createContext, useContext, useState } from 'react';
import { reactLocalStorage } from 'reactjs-localstorage';

// Create a new context for language
const LanguageContext = createContext();

// The provider component manages the language state and provides a way to update it
export const LanguageProvider = ({ children }) => {
  // Initialize the language state from local storage or default to 'en'
  const [language, setLanguage] = useState(reactLocalStorage.get('lang') || 'en');
  console.log("lang inside the context is:::",language)
  // Function to change the language
  const changeLanguage = (lang) => {
    // Store the new language in local storage and update state
    reactLocalStorage.set('lang', lang);
    setLanguage(lang);
  };

  return (
    <LanguageContext.Provider value={{ language, changeLanguage }}>
      {children} {/* Render the children components inside the provider */}
    </LanguageContext.Provider>
  );
};

// Custom hook to use the Language context
export const useLanguage = () => {
  return useContext(LanguageContext);
};
